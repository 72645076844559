import React from 'react'
import GalleryWithLightbox from '../../../components/gallery-with-lightbox'
import Layout from '../../../components/layout/layout'
import Header from '../../../components/header/header'
import Nav from '../../../components/nav/nav'
import Footer from '../../../components/footer/footer'

import imageTheVisitors from '../../../assets/images/albums/paintings/recent-works/the-visitors_acrylic-on-canvas_59x79.5in.jpg'
import imageTimeOfArrival from '../../../assets/images/albums/paintings/recent-works/time-of-arrival_acrylic-on-canvas-59x79.5in.jpg'
import imageTheWaterCollector from '../../../assets/images/albums/paintings/recent-works/the-water-collector.jpg'
import image1 from '../../../assets/images/albums/paintings/recent-works/scape-formation-i_acrylic-on-canvas-105x105in-2016.jpg'
import image2 from '../../../assets/images/albums/paintings/recent-works/scape-formation-ii_acrylic-on-canvas-105x105in-2016.jpg'
import image3 from '../../../assets/images/albums/paintings/recent-works/scape-formation-iii_acrylic-on-canvas-105x105in-2016.jpg'
import image4 from '../../../assets/images/albums/paintings/recent-works/everything-is-connected_acrylic-on-canvas-16x20in-2016.jpg'
import image5 from '../../../assets/images/albums/paintings/recent-works/self-portrait_acrylic-on-cardboard-12x9in-2016.jpg'
import image6 from '../../../assets/images/albums/paintings/recent-works/dancing-after-the-rain_acrylic-on-recycled-wood-135x135in-2016.jpg'
import image7 from '../../../assets/images/albums/paintings/recent-works/looking-for-the-wayout_acrylic-on-cardboard-85x105in-2016.jpg'
import image8 from '../../../assets/images/albums/paintings/recent-works/the-ship-of-oblivion_acrylic-on-recycled-canvas-36x36in-2016.jpg'
import image9 from '../../../assets/images/albums/paintings/recent-works/everything-is-connected-ii_acrylic-on-canvas-36x36in-2016.jpg'
import image10 from '../../../assets/images/albums/paintings/recent-works/touching-down_acrylic-on-recycled-canvas-48x36in-2016.jpg'
import image11 from '../../../assets/images/albums/paintings/recent-works/under-a-berlin-bridge_2017-66lx127wcm-acrylic-canvas.jpg'
import image12 from '../../../assets/images/albums/paintings/recent-works/small-worlds.jpg';
import image13 from '../../../assets/images/albums/paintings/recent-works/ocean-bouquet.jpg';
import image14 from '../../../assets/images/albums/paintings/recent-works/the-last-dive-in.jpg';

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <GalleryWithLightbox
        photos={PHOTO_SET}
        direction='column'
        margin={8}
      />
    </main>
    <Footer />
  </Layout>
)

const PHOTO_SET = [
  {
    src: imageTheVisitors,
    width: 1234,
    height: 960,
    caption: 'The Visitors - acrylic on canvas - 59"Hx79.5"W'
  },
  {
    src: imageTimeOfArrival,
    width: 1292,
    height: 960,
    caption: 'Time of Arrival - acrylic on canvas - 59"Hx79.5"W'
  },
  {
    src: imageTheWaterCollector,
    width: 1160,
    height: 960,
    caption: 'The Water Collector - acrylic on canvas - 60"Hx72"W'
  },
  {
    src: image1,
    width: 944,
    height: 960,
    caption: 'Landscape Formation I tryptich - acrylic on canvas - 10.5"x10.5"'
  },
  {
    src: image2,
    width: 955,
    height: 960,
    caption: 'Landscape Formation II tryptich - acrylic on canvas - 10.5"x10.5"'
  },
  {
    src: image3,
    width: 960,
    height: 957,
    caption: 'Landscape Formation III tryptich - acrylic on canvas - 10.5"x10.5"'
  },
  {
    src: image4,
    width: 960,
    height: 957,
    caption: 'Everything is Connected - acrylic on canvas - 16"x20"'
  },
  {
    src: image5,
    width: 681,
    height: 960,
    caption: 'Self Portrait - acrylic on cardboard - 12"x9"'
  },
  {
    src: image6,
    width: 957,
    height: 960,
    caption: 'Dancing After the Rain - acrylic on recycled wood - 13.5"x13.5"'
  },
  {
    src: image7,
    width: 960,
    height: 767,
    caption: 'Looking for the Way Out - acrylic on cardboard - 8.5"x10.5"'
  },
  {
    src: image8,
    width: 960,
    height: 949,
    caption: 'The Ship of Oblivion - acrylic on recycled canvas - 36"x36"'
  },
  {
    src: image9,
    width: 960,
    height: 952,
    caption: 'Everything is Connected II - acrylic on canvas - 36"x36"'
  },
  {
    src: image10,
    width: 716,
    height: 960,
    caption: 'Touching Down - acrylic on recycled canvas - 48"x36"'
  },
  {
    src: image11,
    width: 960,
    height: 501,
    caption: 'Under a Berlin Bridge - acrylic on canvas - 30"x50"'
  },
  {
    src: image12,
    width: 960,
    height: 960,
    caption: 'Small Worlds - acrylic on canvas mounted on wood - installation of 9 pcs. 8"x8" each'
  },
  {
    src: image13,
    width: 960,
    height: 801,
    caption: 'Ocean Bouquet - acrylic on canvas - 20"x24"'
  },
  {
    src: image14,
    width: 960,
    height: 722,
    caption: 'The Last Dive In - acrylic on canvas - 12"x16"'
  }
]
